import { type Country, siteConfig, siteNames } from '@seek/melways-sites';

import type { CandidateSite } from './types';

export const TRACKING_CODES = {
  contact_us_link_clicked: 'contact-us-link-clicked',
  can_help_centre_link_clicked: 'candidate-help-centre-link-clicked',
  certsy_link_clicked: 'certsy-link-clicked',
  certsy_faq_link_clicked: 'certsy-faq-link-clicked',
  surveymonkey_link_clicked: 'surveymonkey-link-clicked',
  clear_browser_history_link_clicked: 'clear-browser-history-link-clicked',
  microsoft_link_clicked: 'microsoft-link-clicked',
  support_microsoft_link_clicked: 'support-microsoft-link-clicked',
  support_firefox_link_clicked: 'support-firefox-link-clicked',
  firefox_link_clicked: 'firefox-link-clicked',
  chrome_link_clicked: 'chrome-link-clicked',
  support_chrome_link_clicked: 'support-chrome-link-clicked',
  safari_link_clicked: 'safari-link-clicked',
  support_safari_link_clicked: 'support-safari-link-clicked',
  logout_link_clicked: 'logout-link-clicked',
  cs_phone_link_clicked: 'cs-phone-link-clicked',
  sales_phone_link_clicked: 'sales-phone-link-clicked',
  forgot_password_link_clicked: 'forgot-password-link-clicked',
  cand_homepage_link_clicked: 'homepage-link-clicked',
  emp_homepage_link_clicked: 'employer-homepage-link-clicked',
  privacy_policy_link_clicked: 'privacy-policy-link-clicked',
  career_advice_link_clicked: 'career-advice-link-clicked',
  hiring_advice_link_clicked: 'hiring-advice-link-clicked',
  seek_mail_link_clicked: 'seek-mail-link-clicked',
  bugcrowd_link_clicked: 'bugcrowd-link-clicked',
  safe_job_search_link_clicked: 'safe-job-search-link-clicked',
  report_it_link_clicked: 'report-it-link-clicked',
  report_it_to_accc_link_clicked: 'report-it-to-accc-link-clicked',
  report_it_to_seek_link_clicked: 'report-it-to-seek-link-clicked',
  report_security_vulnerabilities_link_clicked:
    'report-security-vulnerabilities-link-clicked',
  terms_link_clicked: 'terms-link-clicked',
  scamwatch_link_clicked: 'scamwatch-link-clicked',
  strategy_grad_program_cta_clicked: 'strategy-grad-program-cta-clicked',
  software_grad_program_cta_clicked: 'software-grad-program-cta-clicked',
  strategy_email_link_clicked: 'strategy-email-link-clicked',
  error_page_displayed: 'error_page_displayed',
  content_element_pressed: 'content_element_pressed',
};

export const BRAND = {
  seek: 'seek',
  jobsdb: 'jobsdb',
  jobstreet: 'jobstreet',
};

export const COUNTRY_NAME_LOOKUP = {
  au: 'Australia',
  nz: 'New Zealand',
  hk: 'Hong Kong',
  th: 'Thailand',
  sg: 'Singapore',
  id: 'Indonesia',
  ph: 'Philippines',
  my: 'Malaysia',
};

export const SITENAME_LOOKUP: Record<Country, CandidateSite> = {
  au: siteNames.candidate.seek.au,
  nz: siteNames.candidate.seek.nz,
  hk: siteNames.candidate.jobsdb.hk,
  th: siteNames.candidate.jobsdb.th,
  sg: siteNames.candidate.jobstreet.sg,
  id: siteNames.candidate.jobstreet.id,
  ph: siteNames.candidate.jobstreet.ph,
  my: siteNames.candidate.jobstreet.my,
} as const;

export const COUNTRY_LOOKUP: Record<CandidateSite, Country> = {
  [siteNames.candidate.seek.au]:
    siteConfig['candidate-seek-au'].classification.country,
  [siteNames.candidate.seek.nz]:
    siteConfig['candidate-seek-nz'].classification.country,
  [siteNames.candidate.jobsdb.hk]:
    siteConfig['candidate-jobsdb-hk'].classification.country,
  [siteNames.candidate.jobsdb.th]:
    siteConfig['candidate-jobsdb-th'].classification.country,
  [siteNames.candidate.jobstreet.sg]:
    siteConfig['candidate-jobstreet-sg'].classification.country,
  [siteNames.candidate.jobstreet.id]:
    siteConfig['candidate-jobstreet-id'].classification.country,
  [siteNames.candidate.jobstreet.ph]:
    siteConfig['candidate-jobstreet-ph'].classification.country,
  [siteNames.candidate.jobstreet.my]:
    siteConfig['candidate-jobstreet-my'].classification.country,
} as const;
